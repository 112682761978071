<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Etiquetas</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCountry"
          :items="countries"
          placeholder="Escoge el pais"
          outlined
          @change="getTags"
          item-text="name"
          item-value=".key"
          class="pl-2"
          rounded
        />
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text mr-5"
            @click="sortDialog = true"
            :loading="loading"
            :disabled="!selectedCountry"
          >
            <v-icon right dark class="mr-3">fas fa-bars</v-icon>
            Ordenar
          </v-btn>
          <v-btn
            color="primary"
            class="ma-2 white--text"
            :disabled="!selectedCountry"
            @click="addTag()"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nueva etiqueta
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="tags"
          :items-per-page="15"
          :search="$store.state.search"
          :loading="loading"
          item-key=".key"
          :sort-by="['position']"
          :sort-desc="[false, true]"
          :single-expand="true"
          fixed-header
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.categoryVisible`]="{ item }">
            <div style="display: flex ; justify-content: center;!important">
              <v-switch
                @change="
                  switchControlChanged(
                    { categoryVisible: item.categoryVisible },
                    item['.key'],
                    item
                  )
                "
                v-model="item.categoryVisible"
              ></v-switch>
            </div>
          </template>

          <template v-slot:[`item.image`]="{ item }">
            <v-avatar v-if="item.image">
              <img :src="item.image.original" alt="John" />
            </v-avatar>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <!-- <div style="display: flex ; justify-content: center;!important"> -->
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
            <!-- </div> -->
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn
              :loading="loading"
              @click="editTag(item)"
              small
              color="primary"
              >Editar</v-btn
            >
            <v-btn
              class="ml-5 white--text"
              :loading="loading"
              @click="deleteTag(item)"
              small
              color="red darken-4"
              >Eliminar</v-btn
            >
          </template>

          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="addDialog" v-if="addDialog" max-width="800px">
      <add
        :position="tags.length + 1"
        :country="selectedCountry"
        :selectedItem="selectedItem"
        @cancel="
          addDialog = false;
          selectedItem = null;
        "
        @success="tagAdded"
      />
    </v-dialog>

    <v-dialog v-model="sortDialog" v-if="sortDialog" max-width="500px">
      <sort
        @success="handleSort"
        @cancel="sortDialog = false"
        :country="selectedCountry"
        :tags="tags"
      ></sort>
    </v-dialog>

    <v-dialog max-width="400" v-model="deleteDialog" v-if="deleteDialog">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="deleteDialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar {{ selectedItem.name }}?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import Lottie from "@/components/Lottie";
import * as animationData from "@/assets/ochoColor.json";
import { db, fb } from "@/firebase";
import add from "./add";
import sort from "./sort";

export default {
  name: "Tags",
  components: {
    lottie: Lottie,
    add,
    sort,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      tags: [],
      countries: [],
      addDialog: false,
      selectedCountry: "",
      sortDialog: false,
      deleteDialog: false,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      headers: [
        { value: "image" },
        { text: "Posición", value: "position" },
        { text: "Nombre", value: "name" },
        // { text: "Visible al filtrar categorías", value: "categoryVisible" },
        { text: "Activo", value: "active" },
        { value: "options", align: "end", width: "250px" },
      ],
    };
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    addTag() {
      this.selectedItem = null;
      this.addDialog = true;
    },

    editTag(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },

    async getTags() {
      this.loading = true;
      await this.$binding(
        "tags",
        db
          .collection(`countries`)
          .doc(this.selectedCountry)
          .collection("tags")
          .where("deleted", "==", false)
      );
      this.loading = false;
    },
    sortByName(data) {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return data.sort(compare);
    },

    deleteTag(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },

    tagAdded() {
      this.snackbarText = this.selectedItem
        ? "Etiqueta editada exitosamente."
        : "Etiqueta agregada exitosamente.";
      this.snackbar = true;
      this.addDialog = false;
    },
    handleSort() {
      this.snackbarText = "Etiquetas ordenadas exitosamente.";
      this.snackbar = true;
      this.sortDialog = false;
    },

    switchControlChanged(data, tagId) {
      if (data && tagId) {
        data.modifiedAt = new Date();
        data.modifiedBy = this.$store.state.user[".key"];
        this.loading = true;
        db.collection(`countries/${this.selectedCountry}/tags`)
          .doc(tagId)
          .update(data)
          .then((ref) => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            // tag.active = !tag.active;
          });
      }
    },

    confirmDelete() {
      if (this.selectedItem) {
        db.collection(`countries/${this.selectedCountry}/tags`)
          .doc(this.selectedItem[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.deleteDialog = false;
            this.snackbar = true;
            this.snackbarText = "Etiqueta eliminada";
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
  },

  mounted() {
    this.$store.commit("setSearchTerm", "etiqueta");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    db.collection("countries")
      .where("deleted", "==", false)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let country = item.data();
          country[".key"] = item.id;
          this.countries.push(country);
        });
        this.countries = this.sortByName(this.countries);
        this.loading = false;
      });
  },
};
</script>